import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { interval, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SwUpdate } from '@angular/service-worker';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { DateConstants } from '@core-constants/dates/date.constants';
import { ToastService } from '@core-services/toast.service';
import { NewVersionDialogComponent } from '@notifications/components/new-version-dialog/new-version-dialog.component';
import { MatchMediaService } from '@media/services/match-media.service';

@Injectable({ providedIn: 'root' })
export class ApplicationUpdateService {

    private readonly currentVersion = new Subject<string>();

    private storedVersion: AppVersion;
    private isNewVersionReadyToInstall: boolean;
    private newVersionDialogDisplayed: boolean;

    constructor(
        private readonly translateService: TranslateService,
        private readonly toaster: ToastService,
        private readonly swUpdate: SwUpdate,
        private readonly matDialog: MatDialog,
        private readonly matchMediaService: MatchMediaService
    ) { }

    public initialize(unsubscribe$: Subject<unknown>): void {
        this.fetchVersion('force-cache', false);

        interval(60 * 1000)
            .pipe(takeUntil(unsubscribe$))
            .subscribe(() => this.swUpdate.isEnabled ? this.checkForUpdate() : this.fetchVersion('reload', true));
    }

    public checkForUpdate(): void {
        this.swUpdate.checkForUpdate()
            .then(isNewVersionAvailable => {
                if (isNewVersionAvailable || this.isNewVersionReadyToInstall) {
                    this.isNewVersionReadyToInstall = true;

                    const isMobile = new Set(['xs', 'sm', 'md']).has(this.matchMediaService.activeMediaQuery);

                    isMobile
                        ? this.showUpdateDialog()
                        : this.showUpdateNotification();
                }
            })
            .catch(error => console.error('Check for updates failed:', error));
    }

    public getCurrentVersion(): Observable<string> {
        return this.currentVersion;
    }

    public showUpdateNotification(): void {
        // TODO : create custom toastr component
        const message = `<a>${this.translateService.instant('NOTIFICATIONS_COMMON.ALERTS.NEW_VERSION_TOAST')}</a>`;
        const toast = this.toaster.showTranslatedClientInfo(
            message,
            null,
            { disableTimeOut: true, timeOut: 0, extendedTimeOut: 0, enableHtml: true, toastClass: 'ngx-toastr update-toast' });

        toast.toastRef.afterActivate().pipe(take(1)).subscribe(() => {
            const linkElement = document.querySelector('.update-toast a');

            if (linkElement != null) {
                linkElement.addEventListener('click', () => this.applyUpdate());
            }
        });
    }

    public showUpdateDialog(): void {
        if (this.newVersionDialogDisplayed) {
            return;
        }

        this.newVersionDialogDisplayed = true;
        const dialogConfig: MatDialogConfig = { autoFocus: false, restoreFocus: false, disableClose: true, panelClass: 'rpc-new-version-modal' };

        const dialogRef = this.matDialog.open(NewVersionDialogComponent, dialogConfig);

        dialogRef.componentInstance.update
            .pipe(take(1))
            .subscribe(() => {
                dialogRef.close();

                this.newVersionDialogDisplayed = false;
                this.applyUpdate();
            });
    }

    public applyUpdate(): void {
        if (!this.swUpdate.isEnabled) {
            document.location.reload();

            return;
        }

        this.swUpdate.activateUpdate()
            .then(() => {
                this.isNewVersionReadyToInstall = false;

                document.location.reload();
            })
            .catch(error => console.error('Failed to apply updates:', error));
    }

    public getVersion(version: AppVersion = this.storedVersion): string {
        return version != null ? `${version.version.major}.${version.version.minor}.${version.version.patch}` : '';
    }

    public getBuildDate(version: AppVersion = this.storedVersion): string {
        return version != null ? `${moment(new Date(version.build.date)).format(`${DateConstants.Formats.ShortDateSlash} h:mm:ss A`)}` : '';
    }

    private fetchVersion(cache: 'force-cache' | 'reload', showUpdateDialog: boolean): void {
        fetch('version.json', { cache }).then(response => {
            response.json().then((appVersion: AppVersion) => {
                if (this.storedVersion == null) {
                    this.storedVersion = appVersion;
                    this.currentVersion.next(this.formatCurrentVersion());
                }

                if (showUpdateDialog && moment(new Date(appVersion.build.date)).isAfter(new Date(this.storedVersion.build.date))) {
                    this.showUpdateDialog();
                }

            }).catch(() => { });
        }).catch(() => { });
    }

    private formatCurrentVersion(): string {
        return this.storedVersion == null
            ? null
            : `Version: ${this.getVersion(this.storedVersion)} | Published: ${this.getBuildDate(this.storedVersion)}`;
    }
}

export class AppVersion {
    version: {
        major: number;
        minor: number;
        patch: number;
    };

    build: {
        date: string;
    };
}