import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Debounce } from '@core-decorators/debounce.decorator';
import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';

@Component({
    selector: 'rpc-menu-button',
    templateUrl: './rpc-menu-button.component.html',
    styleUrls: ['./rpc-menu-button.component.scss'],
    exportAs: 'rpcMenuButtonApi',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RpcMenuButtonComponent implements OnInit, OnChanges {

    @ViewChild('rpcMenuTrigger') rpcMenuTrigger: MatMenuTrigger;

    @Input() public title?: string;
    @Input() public alt?: string;
    @Input() public iconName?: string;
    @Input() public actionClass?: string;
    @Input() public actionTrigger?: boolean = null;
    @Input() public menuActionClass?: string;
    @Input() public menuActionTrigger?: boolean = null;
    @Input() public menuIconVisible? = true;
    @Input() public badgeIcon?: boolean;
    @Input() public badgeIconName?: string;
    @Input() public appearanceType?: 'normal' | 'stroke' = 'normal';
    @Input() public avatarVisible?: boolean = null;
    @Input() public disabled?: boolean;
    @Input() public enableCustomBackground?: boolean = null;
    @Input() public type?: 'submit' | 'reset' | 'button' = 'button';
    @Input() public shouldTranslate? = true;

    @Output() public clicked = new EventEmitter<void>();

    public menuOpened = false;

    constructor(
        public element: ElementRef,
        private readonly renderer: Renderer2,
    ) { }

    @HostListener('window:resize')
    @Debounce(0)
    public onResize(): void {
        const currtentElement = this.element.nativeElement as HTMLElement;

        if (currtentElement.hidden) {
            this.closeMenu();
        }
    }

    public ngOnInit(): void {
        if (this.alt == null) {
            this.alt = this.title;
        }
    }

    public ngOnChanges(changes: SimpleChanges<RpcMenuButtonComponent>): void {
        if (changes.title != null && !changes.title.firstChange && changes.title.currentValue != null && changes.alt == null && this.alt !== changes.title.currentValue) {
            this.alt = changes.title.currentValue;
        }
    }

    public onClick(): void {
        this.menuOpened = true;
        this.renderer.addClass(document.body, 'modal-open');
        this.clicked.emit();
    }

    public onClosed(): void {
        this.menuOpened = false;
        this.renderer.removeClass(document.body, 'modal-open');
    }

    public closeMenu(): void {
        this.rpcMenuTrigger.closeMenu();
        this.onClosed();
    }
}