import { NgModule } from '@angular/core';

import { CastPipe } from '@core-pipes/cast/cast.pipe';
import { CommaSeparatorPipe } from '@core-pipes/comma-separator/comma-separator.pipe';
import { DateLabelPipe } from '@core-pipes/date-label/date-label.pipe';
import { FilterCollectionPipe } from '@core-pipes/filter-collection/filter-collection.pipe';
import { locale as english } from '@core-pipes/i18n/en';
import { ShortNumberPipe } from '@core-pipes/short-number/short-number.pipe';
import { SortCollectionPipe } from '@core-pipes/sort-collection/sort-collection.pipe';
import { TimePeriodLabelPipe } from '@core-pipes/time-period-label/time-period-label.pipe';
import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { BaseFilterCollectionPipe } from './filter-collection/base-filter-collection.pipe';
import { ReplacePipe } from './replace/replace.pipe';
import { SanitizePipe } from './sanitize/sanitize.pipe';
import { BypassSecurityTrustPipe } from './bypass-security-trust/bypass-security-trust.pipe';
import { TitlePipe } from './title/title.pipe';

const declaredExportModules = [
    CommaSeparatorPipe,
    DateLabelPipe,
    ShortNumberPipe,
    TimePeriodLabelPipe,
    SortCollectionPipe,
    FilterCollectionPipe,
    CastPipe,
    BaseFilterCollectionPipe,
    BypassSecurityTrustPipe,
    ReplacePipe,
    SanitizePipe,
    TitlePipe
];

@NgModule({
    declarations: [declaredExportModules],
    exports: [declaredExportModules]
})
export class RpcPipesModule {
    constructor(private readonly translationLoaderService: TranslationLoaderService) {
        this.translationLoaderService.loadTranslations(english);
    }
}