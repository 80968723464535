
import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector, Type } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';

import { ServerMessageService } from '@core-services/server-message.service';
import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { ApiError } from '@error/models/api-error';
import { MatchMediaService } from '@media/services/match-media.service';
import { HybridService } from '../hybrid/hybrid.service';

@Injectable({ providedIn: 'root' })
export class ToastService {

    constructor(
        private readonly toastr: ToastrService,
        private readonly serverMessageService: ServerMessageService,
        private readonly translateService: TranslateService,
        private readonly translationLoaderService: TranslationLoaderService,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly injector: Injector,
        private readonly applicationRef: ApplicationRef,
        private readonly hybridService: HybridService,
        private readonly matchMediaService: MatchMediaService
    ) {
        this.translationLoaderService.loadTranslations({
            lang: 'en',
            data: {
                COMMON_ERRORS: {
                    SERVER: 'Something went wrong.',
                    MESSAGE_UNKNOWN: 'Failed to show correct notification message.',
                }
            }
        });
    }

    public showServerError(error: ApiError | string, config: Partial<IndividualConfig> = {}): void {
        if (typeof error === 'string') {
            this.showServerMessage(this.toastr.error.bind(this.toastr), error, config);
        } else {
            if (error != null && error.errorKey != null && error.errorKey !== '') {
                this.showServerMessage(this.toastr.error.bind(this.toastr), error.errorKey, config);
            } else {
                this.showClientError('COMMON_ERRORS.SERVER', null, config);
            }
        }
    }

    public showClientSuccess(
        clientTranslationKey: string, titleTranslationKey?: string, config: Partial<IndividualConfig> = {}
    ): ActiveToast<unknown> {
        return this.showClientMessage(this.toastr.success.bind(this.toastr), clientTranslationKey, titleTranslationKey, config);
    }

    public showClientError(
        clientTranslationKey: string, titleTranslationKey?: string, config: Partial<IndividualConfig> = {}
    ): ActiveToast<unknown> {
        return this.showClientMessage(this.toastr.error.bind(this.toastr), clientTranslationKey, titleTranslationKey, config);
    }

    public showClientWarning(
        clientTranslationKey: string, titleTranslationKey?: string, config: Partial<IndividualConfig> = {}
    ): ActiveToast<unknown> {
        return this.showClientMessage(this.toastr.warning.bind(this.toastr), clientTranslationKey, titleTranslationKey, config);
    }

    public showTranslatedClientInfo(
        message: string, title?: string, config: Partial<IndividualConfig> = {}
    ): ActiveToast<unknown> {
        return this.showTranslatedMessage(this.toastr.info.bind(this.toastr), message, title, config);
    }

    public showCustomToast<T>(component: Type<T>, config: Partial<IndividualConfig> = {}): { componentRef: ComponentRef<T>, toast: ActiveToast<T> } {
        const defaultConfig: Partial<IndividualConfig> = {
            closeButton: false,
            progressBar: false,
            tapToDismiss: false,
        };

        const toast = this.toastr.show(null, null, { ...defaultConfig, ...config });

        const toastElementId = `custom-toast-${toast.toastId}`;
        const toastElement = document.getElementById('toast-container').children[0];

        toastElement.id = toastElementId;
        toastElement.classList.add('toast-custom');

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        const toastContainer = document.createElement('div');
        const componentRef = componentFactory.create(this.injector, [], toastContainer);

        toast.onShown.pipe(take(1)).subscribe(() => {
            document.getElementById(toastElementId).appendChild(toastContainer);

            this.applicationRef.attachView(componentRef.hostView);
        });

        return { componentRef, toast };
    }

    private showServerMessage(
        showAction: (message: string, title?: string, config?: Partial<IndividualConfig>) => void,
        serverMessageMappingKey: string,
        config: Partial<IndividualConfig> = {}
    ): void {
        const commonMessage = this.serverMessageService.getMessage(serverMessageMappingKey);

        if (commonMessage == null) {
            const message = this.translateService.instant('COMMON_ERRORS.MESSAGE_UNKNOWN') as string;

            this.toastr.error(message, null, this.getToastConfig(config));
        } else {
            showAction(commonMessage.message, commonMessage.title, this.getToastConfig(config));
        }
    }

    private showClientMessage(
        showAction: (message: string, title?: string, config?: Partial<IndividualConfig>) => ActiveToast<unknown>,
        clientTranslationKey: string,
        titleTranslationKey?: string,
        config: Partial<IndividualConfig> = {}
    ): ActiveToast<unknown> {
        let title: string = null;
        const message = this.translateService.instant(clientTranslationKey) as string;

        if (titleTranslationKey != null && titleTranslationKey !== '') {
            title = this.translateService.instant(titleTranslationKey) as string;
        }

        return showAction(message, title, this.getToastConfig(config));
    }

    private showTranslatedMessage(
        showAction: (message: string, title?: string, config?: Partial<IndividualConfig>) => ActiveToast<unknown>,
        message: string,
        title?: string,
        config: Partial<IndividualConfig> = {}
    ): ActiveToast<unknown> {
        return showAction(message, title, this.getToastConfig(config));
    }

    private getToastConfig(config: Partial<IndividualConfig> = {}): Partial<IndividualConfig> {
        const isMobile = this.hybridService.isHybrid$.value || new Set(['xs', 'sm', 'md']).has(this.matchMediaService.activeMediaQuery);

        return isMobile ? { ...config, progressBar: false } : config;
    }
}