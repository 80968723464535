export const locale = {
    lang: 'en',
    data: {
        NEW_VERSION_DIALOG: {
            TITLES: {
                UPDATE_AVAILABLE: 'Update available',
                NEW_VERSION: 'A new version of {{productName}} has been installed! Please restart the app by clicking the button below.',
                RELAUNCH_NOW: 'Relaunch now'
            }
        }
    }
};