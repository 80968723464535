import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'title' })
export class TitlePipe implements PipeTransform {

    /**
     * formatTitle must be pure function
     */
    transform<T>(value: T, formatTitle: (value: T, ...args: unknown[]) => string, ...args: unknown[]): string {
        return formatTitle(value, ...args);
    }
}
