<mat-form-field appearance="outline" class="chip-wrap"
    [ngClass]="{ 'chip-wrap--focused' : ( isOmniBoxOpened || isPatternResultsOpened ) && selectedValues.length !== 0}">

    <mat-chip-list #chipList class="chip-list-box" [disabled]="disabled">
        <rpc-icon class="chip-icon" [iconName]="'pin'"></rpc-icon>
        <div #chipScrollContainer id="chipScrollContainer" class="chip-list-wrap scroll--accent">
            <div class="chip-list ">
                <mat-basic-chip class="chip-item" [title]="selectedValue" *ngFor="let selectedValue of selectedValues"
                    [color]="'accent'" [removable]="true" (removed)="removeValue(selectedValue)" (focus)="onFocus()">
                    <div class="chip-item-text">
                        {{ selectedValue }}
                    </div>
                    <rpc-icon class="chip-item-icon" matChipRemove [iconName]="'decline'"
                        [title]="'OMNI_SEARCH.TITLES.CANCEL' | translate" (focus)="$event.stopPropagation()">
                    </rpc-icon>
                </mat-basic-chip>

                <div class="chip-list-empty" *ngIf="!isOmniBoxOpened && !isPatternResultsOpened"
                    (click)="searchInput.focus()">
                    <div class="chip-list-empty-text">
                        {{ (shortPlaceholder ? 'OMNI_SEARCH.TITLES.SHORT_PLACEHOLDER' :'OMNI_SEARCH.TITLES.PLACEHOLDER')
                        | translate }}
                    </div>
                </div>

                <input class="chip-input" #searchInput [formControl]="valuesFormControl" [matAutocomplete]="auto"
                    (focus)="onFocus()" [matChipInputFor]="chipList" (blur)="onBlur($event)">
            </div>
        </div>
    </mat-chip-list>

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectValue($event.option.value)"
        (opened)="isPatternResultsOpened = true"
        (closed)="isPatternResultsOpened = false; onSearchInputFocusChange(false)">
        <mat-optgroup class="chip-dropdown-group" *ngFor="let group of patternSearchResultGroupMapping"
            [label]="group.type">
            <ng-container *ngFor="let groupValue of group.patternSearchResultGroup.values">
                <mat-divider class="chip-dropdown-group-divider"></mat-divider>
                <mat-option [value]="groupValue">
                    <rpc-icon class="chip-dropdown-group-icon" *ngIf="group.patternSearchResultGroup.icon"
                        [iconName]="group.patternSearchResultGroup.icon">
                    </rpc-icon>
                    <span class="chip-dropdown-group-text">
                        {{ groupValue.value }}
                    </span>
                </mat-option>
                <mat-divider class="chip-dropdown-group-divider"></mat-divider>
            </ng-container>
        </mat-optgroup>
    </mat-autocomplete>

</mat-form-field>