import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatMenuTrigger } from '@angular/material/menu';

import { AnimationConfig } from '@core-controls/models/animation-config';
import { Debounce } from '@core-decorators/debounce.decorator';
import { nameof } from '@core-models/utilities/nameof';
import { PlatformService } from '@core-services/platform.service';

@Component({
    selector: 'rpc-menu-icon-button',
    templateUrl: './rpc-menu-icon-button.component.html',
    styleUrls: ['./rpc-menu-icon-button.component.scss'],
    exportAs: 'rpcMenuIconButtonApi',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RpcMenuIconButtonComponent implements OnInit {

    @ViewChild('rpcMenuTrigger') rpcMenuTrigger: MatMenuTrigger;

    @Input() public alt?: string;
    @Input() public iconName: string;
    @Input() public actionClass?: string;
    @Input() public actionTrigger?: boolean = null;
    @Input() public menuActionClass?: string;
    @Input() public menuActionTrigger?: boolean = null;
    @Input() public badgeCount?: number;
    @Input() public badgeCountAlert?: number;
    @Input() public badgeCountAlertLimit?: number;
    @Input() public animationConfig: AnimationConfig = null;
    @Input() public disabled?: boolean;
    @Input() public color?: ThemePalette;
    @Input() public enableCustomBackground?: boolean = null;
    @Input() public type?: 'submit' | 'reset' | 'button' = 'button';

    @Output() public clicked = new EventEmitter<void>();
    @Output() public closed = new EventEmitter<void>();

    public menuOpened = false;

    constructor(
        public readonly elementRef: ElementRef,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly platformService: PlatformService,
        private readonly renderer: Renderer2,
    ) { }

    @HostListener('window:resize')
    @Debounce(0)
    public onResize(): void {
        const currtentElement = this.elementRef.nativeElement as HTMLElement;

        if (currtentElement.hidden) {
            this.closeMenu();
        }
    }

    @HostListener('window:scroll')
    public scrollHandler(): void {
        if (!this.platformService.isMobile) {
            this.closeMenu();
        }
    }

    public ngOnInit(): void {
        if (this.alt == null || this.alt === '') {
            throw new Error(`'${nameof<RpcMenuIconButtonComponent>('alt')}' is required.`);
        }

        if (this.iconName == null || this.iconName === '') {
            throw new Error(`'${nameof<RpcMenuIconButtonComponent>('iconName')}' is required.`);
        }
    }

    public onClick(): void {
        this.menuOpened = true;
        this.renderer.addClass(document.body, 'modal-open');

        this.changeDetectorRef.detectChanges();
        this.clicked.emit();
    }

    public openMenu(): void {
        this.rpcMenuTrigger.openMenu();
        this.onClick();
    }

    public onClosed(): void {
        this.menuOpened = false;
        this.renderer.removeClass(document.body, 'modal-open');

        this.changeDetectorRef.detectChanges();
        this.closed.emit();
    }

    public closeMenu(): void {
        this.rpcMenuTrigger.closeMenu();
        this.onClosed();
    }
}