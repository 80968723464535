import { locale as appointmentNotification } from '@notifications/components/appointment-notification/i18n/en';
import { locale as notifications } from '@notifications/components/notifications/i18n/en';
import { locale as notificationGroup } from '@notifications/components/notification-group/i18n/en';
import { locale as notificationItem } from '@notifications/components/notification-item/i18n/en';
import { locale as notificationList } from '@notifications/components/notification-list/i18n/en';
import { locale as notificationCheckbox } from '@notifications/components/notification-checkbox/i18n/en';
import { locale as newVersionDialog } from '@notifications/components/new-version-dialog/i18n/en';

export const locale = {
    lang: 'en',
    data: {
        ...appointmentNotification.data,
        ...notifications.data,
        ...notificationGroup.data,
        ...notificationItem.data,
        ...notificationList.data,
        ...notificationCheckbox.data,
        ...newVersionDialog.data,

        NOTIFICATIONS_COMMON: {
            ALERTS: {
                NEW_COMMENTS: 'You have **unread** messages.',
                NEW_APPOINTMENT: '{{requestorFullName}} **created** a new appointment request.',
                NEW_APPOINTMENT_CURRENT_USER_PARTICIPATE: 'You have a new appointment **request**.',
                APPOINTMENT_CONFIRMED: '{{updaterFullName}} **confirmed** {{requestorFullName}}\'s appointment request.',
                APPOINTMENT_CONFIRMED_CURRENT_USER_PARTICIPATE: 'You have a new **confirmed** appointment.',
                APPOINTMENT_DECLINED: '{{updaterFullName}} **declined** {{requestorFullName}}\'s appointment request.',
                APPOINTMENT_DECLINED_CURRENT_USER_PARTICIPATE: 'A requested appointment has been **declined**.',
                NEW_VERSION: 'New version is available. Please, update the application.',
                NEW_VERSION_TOAST: 'New version is available. Please, click to update the application.'
            }
        },
        SERVER: {
            ERRORS: {
                SIGNALR_CONNECTION_NOT_FOUND: 'Unable to configure real-time notifications mechanism.'
            }
        }
    }
};