import { Component, EventEmitter, Output } from '@angular/core';
import { map } from 'rxjs';

import { AppConfigurationService } from '@app-config/services/app-configuration.service';

@Component({
    selector: 'new-version-dialog',
    templateUrl: './new-version-dialog.component.html',
    styleUrls: ['./new-version-dialog.component.scss']
})
export class NewVersionDialogComponent {
    @Output() public update = new EventEmitter<void>();

    public readonly productName$ = this.configurationService.configuration$.pipe(map(configuration => configuration.company.productName));

    constructor(private readonly configurationService: AppConfigurationService) { }

    public onUpdateClicked(): void {
        this.update.emit();
    }
}
