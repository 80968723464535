import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ToastComponent } from './components/toast/toast.component';
import { RpcIconModule } from '@core-utils/rpc-icon/rpc-icon.module';
import { RpcControlsModule } from '@core-controls/controls.module';
import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { locale as english } from './i18n/en';
import { RpcDirectivesModule } from '@core-directives/directives.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,

        RpcIconModule,
        RpcControlsModule,
        RpcDirectivesModule
    ],
    declarations: [ToastComponent]
})
export class ToastModule {
    constructor(private readonly translationLoaderService: TranslationLoaderService) {
        this.translationLoaderService.loadTranslations(english);
    }
}