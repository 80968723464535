import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { RoutesRecognized } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subject, combineLatest, Observable } from 'rxjs';
import { takeUntil, map, take } from 'rxjs/operators';

import { SplashScreenService } from '@core-services/splash-screen.service';
import { CommunicationSignalRService } from '@notifications/services/communication-signalr.service';
import { SyncLoginStateService } from '@auth/services/sync-login-state.service';
import { ApplicationUpdateService } from '@notifications/services/application-update.service';
import { CustomerActivityService } from '@customer-activity/customer-activity.service';
import { AppConfigurationService } from '@app-config/services/app-configuration.service';
import { AppConfiguration } from '@app-config/models/app-configuration';
import { UserStoreService } from '@auth/store/services/user-store.service';
import { RouteService } from './services/route.service';
import { PageRefresherService } from './services/page-refresher.service';
import { SettingsStoreService } from '@settings/store/services/settings-store.service';
import { AppStoreService } from './store/services/app-store.service';
import { AgentsStoreService } from '@agents/store/services/agents-store.service';
import { CollaborationSpaceStoreService } from '@auth/store/services/collaboration-space-store.service';
import { UsersStoreService } from '@users/store/services/users-store.service';
import { ProfileBaseStoreService } from 'app/modules/user-modules/profile-modules/profile-base/store/services/profile-base-store.service';
import { NotificationsStoreService } from '@notifications/store/services/notifications-store.service';
import { OnboardingDialogPopupService } from 'app/modules/onboarding/services/onboarding-dialog-popup.service';
import { SavedSearchStoreService } from '@saved-search/store/services/saved-search-store.service';
import { ScrollService } from '@core-layout/scroll-to-top/scroll.service';
import { HybridService } from '../../hybrid/hybrid.service';
import { MatchMediaService } from '@media/services/match-media.service';
import { StorageService } from '@auth/services/storage.service';
import { SmartBannerService } from '@core-layout/smart-banner/smart-banner.service';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
    public configuration: AppConfiguration;
    public hasThemeSidebar = false;
    public isUserAuthorized: boolean;
    public isDarkMode: boolean;
    public readonly isHybrid$ = this.hybridService.isHybrid$;
    public readonly isShowSmartBanner$ = this.smartBannerService.isShow();
    public readonly isMobile$ = this.matchMediaService.onMediaChange.pipe(map(mediaQuery => mediaQuery === 'xs' || mediaQuery === 'sm' || mediaQuery === 'md'));

    private readonly notAuthorizedClassName = 'not-authorized';
    private readonly unsubscribe$ = new Subject<void>();

    private isConfigsLoaded = false;

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly configurationService: AppConfigurationService,
        // SplashScreenService must be injected for initialization
        private readonly splashScreenService: SplashScreenService,
        private readonly translateService: TranslateService,
        private readonly titleService: Title,
        private readonly communicationSignalRService: CommunicationSignalRService,
        private readonly syncLoginStateService: SyncLoginStateService,
        private readonly applicationUpdateService: ApplicationUpdateService,
        private readonly customerActivityService: CustomerActivityService,
        private readonly userStoreService: UserStoreService,
        private readonly routeService: RouteService,
        private readonly settingsStoreService: SettingsStoreService,
        private readonly appStoreService: AppStoreService,
        private readonly agentsStoreService: AgentsStoreService,
        private readonly usersStoreService: UsersStoreService,
        @Inject('IS_PRODUCTION') private readonly isProduction: boolean,
        @Inject('IS_STAGE') private readonly isStage: boolean,
        private readonly collaborationSpaceStoreService: CollaborationSpaceStoreService,
        private readonly profileBaseStoreService: ProfileBaseStoreService,
        private readonly notificationsStoreService: NotificationsStoreService,
        private readonly savedSearchStoreService: SavedSearchStoreService,
        private readonly onboardingDialogPopupService: OnboardingDialogPopupService,
        private readonly scrollService: ScrollService,
        private readonly hybridService: HybridService,
        private readonly matchMediaService: MatchMediaService,
        private readonly smartBannerService: SmartBannerService
    ) {
        this.translateService.addLangs(['en']);
        this.translateService.setDefaultLang('en');
        this.translateService.use('en');

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */
    }

    public ngOnInit(): void {
        this.getScreenSize();
        this.subscribeToLayoutReset();
        this.createAppConfigurationSubscription();
        this.createCompanyConfigurationSubscription();
        this.routeService.getCurrentRouteDataProperty<boolean>('hasThemeSidebar')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(hasThemeSidebar => {
                this.hasThemeSidebar = !this.isProduction && !this.isStage && hasThemeSidebar != null && hasThemeSidebar;
            });

        this.syncLoginStateService.initialize();
        this.customerActivityService.initialize().catch(() => { });

        this.applicationUpdateService.initialize(this.unsubscribe$);

        this.loadSettings();

        this.routeService.registerNavigationHandler()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe();

        this.scrollService.registerScrollHandler();

        this.userStoreService.getIsAuthorized()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(isUserAuthorized => {
                this.isUserAuthorized = isUserAuthorized;

                if (this.isUserAuthorized) {
                    this.usersStoreService.loadCustomers();
                    this.agentsStoreService.loadAgents();
                    this.notificationsStoreService.loadNotifications();
                    this.savedSearchStoreService.loadSavedSearches();

                    if (!this.hybridService.isHybrid$.value) {
                        this.collaborationSpaceStoreService.loadHasMultipleCollaborationSpaces();
                    }

                    this.document.body.classList.remove(this.notAuthorizedClassName);
                } else {
                    this.document.body.classList.add(this.notAuthorizedClassName);

                    this.splashScreenService.hide();
                }
            });

        this.isDarkMode = StorageService.getDarkMode();
        combineLatest([
            this.settingsStoreService.getSettings(),
            this.configurationService.getCompanyConfiguration(),
            this.userStoreService.getIsAuthorized()
        ])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([settings, companyConfiguration, isUserAuthorized]) => {
                if (settings?.layoutSettings != null && companyConfiguration != null) {
                    this.isDarkMode = isUserAuthorized ? settings.layoutSettings.darkMode : false;
                    this.setTheme(companyConfiguration.theme, companyConfiguration.darkMode);

                    this.configurationService.setCompanyLogo(companyConfiguration, this.isDarkMode);
                }

                if (!this.isConfigsLoaded) {
                    this.isConfigsLoaded = true;
                    this.splashScreenService.hide();
                }
            });

        this.userStoreService.getCompanyId()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(companyId => {
                if (companyId != null) {
                    this.appStoreService.loadCompanyConfigurationByInternalId(companyId);
                } else {
                    this.appStoreService.loadDomainCompanyConfiguration();
                }
            });

        this.userStoreService.isPrimaryCustomer$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(isPrimary => {
                if (isPrimary) {
                    this.profileBaseStoreService.loadAdditionalProfiles({ isActiveOnly: false });
                }
            });
    }

    public ngAfterViewInit(): void {
        PageRefresherService.rewriteMobilePageRefresh('.global-content', '.toolbar-wrap', ['.map-view', '.map-view-wrap']);
        this.tryOpenOnboardingPopup();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    @HostListener('window:beforeunload')
    public onWindowUnload(): void {
        this.userStoreService.getUser()
            .pipe(take(1))
            .subscribe(user => {
                if (user != null) {
                    this.communicationSignalRService.removeNotificationHub();
                    this.sleep(500);
                }
            });
    }

    @HostListener('window:resize')
    public getScreenSize(): void {
        this.document.documentElement.style.setProperty('--vh', window.innerHeight + 'px');
    }

    private createAppConfigurationSubscription(): void {
        this.configurationService.configuration$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((configuration: AppConfiguration) => {
                if (configuration.company != null && (this.configuration == null || this.configuration.company != null)) {
                    this.setTheme(configuration.company.theme, configuration.company.darkMode);
                }

                this.configuration = configuration;
            });
    }

    private setTheme(themeName: string, themeDarkModeName: string): void {
        if (themeName == null) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < this.document.body.classList.length; i++) {
            const className = this.document.body.classList[i];

            if (className.startsWith('theme-')) {
                this.document.body.classList.remove(className);
            }
        }

        this.isDarkMode ? this.document.body.classList.add(themeDarkModeName) : this.document.body.classList.add(themeName);
    }

    private createCompanyConfigurationSubscription(): void {
        combineLatest([
            this.routeService.getCurrentRouteDataProperty<string>('title'),
            this.getCompanyNamePipe()
        ]).pipe(
            takeUntil(this.unsubscribe$),
            map(([providedTitle, companyName]) => this.getCorrectedTitle(providedTitle, companyName))
        ).subscribe(title => {
            if (title != null) {
                this.titleService.setTitle(title);
            }
        });
    }

    private getCompanyNamePipe(): Observable<string> {
        return this.configurationService.configuration$
            .pipe(
                takeUntil(this.unsubscribe$),
                map((configuration: AppConfiguration) => configuration.company.companyName)
            );
    }

    private getCorrectedTitle(providedTitle: string, companyName: string): string {
        return providedTitle != null && companyName != null
            ? providedTitle + ' | ' + companyName
            : null;
    }

    private loadSettings(): void {
        this.userStoreService.getIsAuthorized()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(isUserAuthorized => {
                if (isUserAuthorized) {
                    this.settingsStoreService.loadSettings();
                    this.settingsStoreService.loadNeighborhoodsMapping();
                }
            });
    }

    private tryOpenOnboardingPopup(): void {
        this.userStoreService.getIsAuthorized()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(isUserAuthorized => {
                if (isUserAuthorized) {
                    this.onboardingDialogPopupService.openDialog();
                }
            });
    }

    private subscribeToLayoutReset(): void {
        // Reload the default layout config on every RoutesRecognized event
        // if the current layout config is different from the default one
        // to rewert layout changes done on pages
        this.routeService.getCurrentRouteDataProperty<boolean>('applyDefaultLayout', RoutesRecognized)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(applyDefaultLayout => {
                this.configurationService.resetLayout((applyDefaultLayout == null || applyDefaultLayout));
            });
    }

    /**
     * Syncronious delay
     * 
     * @param delay Delay in milliseconds
     */
    private sleep(delay): void {
        const start = new Date().getTime();

        while (new Date().getTime() < start + delay);
    }
}