import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';
import { NeighborhoodProperty } from '@on-market/models/neighborhood-property';
import { PatternSearchResult } from '@search/models/pattern-search-result';
import { ScrollService } from '@core-layout/scroll-to-top/scroll.service';

@Component({
    selector: 'omni-search',
    templateUrl: './omni-search.component.html',
    styleUrls: ['./omni-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OmniSearchComponent implements OnInit, OnChanges, OnDestroy {

    @Input() public neighborhoodProperties: NeighborhoodProperty[];
    @Input() public selectedPatternSearchResults: PatternSearchResult[];
    @Input() public patternSearchResults: PatternSearchResult[];
    @Input() public shortPlaceholder?: boolean = false;
    @Input() public disabled?: boolean = false;

    @Output() public readonly patternChanged = new EventEmitter<string>();
    @Output() public readonly patternResultChanged = new EventEmitter<PatternSearchResult[]>();

    @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    @ViewChild('chipScrollContainer') chipScrollContainer: ElementRef<HTMLDivElement>;

    private readonly unsubscribe$ = new Subject<void>();

    public valuesFormControl = new FormControl();
    public selectedValues: string[] = [];
    public selectedCurrentPatternSearchResults: PatternSearchResult[] = [];
    public isPatternResultsOpened = false;
    public isOmniBoxOpened = false;
    public patternSearchResultGroupMapping: { type: string, patternSearchResultGroup: PatternSearchResultGroup }[] = [];

    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly scrollService: ScrollService,
    ) { }

    public ngOnChanges(changes: SimpleChanges<OmniSearchComponent>): void {
        if (changes.selectedPatternSearchResults != null && changes.selectedPatternSearchResults.currentValue != null) {
            this.selectedCurrentPatternSearchResults = [];
            this.selectedValues = [];
            changes.selectedPatternSearchResults.currentValue.forEach(value => {
                this.selectedCurrentPatternSearchResults.push(value);
                this.selectedValues.push(value.text);
            });
        }

        if (changes.patternSearchResults != null && changes.patternSearchResults.currentValue != null && (this.valuesFormControl.value != null && this.valuesFormControl.value !== '')) {
            this.patternSearchResultGroupMapping = [];
            changes.patternSearchResults.currentValue.forEach(result => {
                if (this.selectedValues.includes(result.text)) {
                    return;
                }

                const existingGroup = this.patternSearchResultGroupMapping.find(group => group.type === result.type);

                if (existingGroup != null) {
                    existingGroup.patternSearchResultGroup.values.push({ value: result.text, keys: result.keys });
                } else {
                    this.patternSearchResultGroupMapping.push({
                        type: result.type,
                        patternSearchResultGroup: { values: [{ value: result.text, keys: result.keys }], icon: this.getIcon(result.type) }
                    });
                }
            });
            this.changeDetectorRef.detectChanges();
        }
    }

    public ngOnInit(): void {
        this.valuesFormControl.valueChanges
            .pipe(takeUntil(this.unsubscribe$), debounceTime(200))
            .subscribe((searchPattern: string) => {
                this.patternSearchResultGroupMapping = [];

                if (searchPattern != null && searchPattern.length > 1) {
                    this.patternChanged.emit(searchPattern);
                }
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public removeValue(value: string): void {
        const index = this.selectedValues.indexOf(value);

        if (index >= 0) {
            this.selectedValues.splice(index, 1);
        }

        const mathcingPatternIndex = this.selectedCurrentPatternSearchResults.findIndex(patternResult => patternResult.text === value);

        if (mathcingPatternIndex >= 0) {
            this.selectedCurrentPatternSearchResults.splice(mathcingPatternIndex, 1);
        }

        this.searchInput.nativeElement.focus();
        // reset box state
        this.onSearchInputFocusChange(false);

        this.patternResultChanged.emit(this.selectedCurrentPatternSearchResults);
    }

    public selectValue(groupValue: PatternSearchResultGroupValue): void {
        this.patternSearchResultGroupMapping = [];
        this.selectedValues.push(groupValue.value);
        this.searchInput.nativeElement.value = '';
        this.searchInput.nativeElement.focus();
        this.valuesFormControl.reset();

        const mathcingPattern = this.patternSearchResults.find(patternResult => patternResult.keys.every(key => groupValue.keys.includes(key)));

        let adjustedKeys: number[] = mathcingPattern.keys;

        if (['borough', 'section', 'neighborhood'].includes(mathcingPattern.type.toLowerCase())) {
            adjustedKeys = this.getNeighborhoodChildrenIds(this.neighborhoodProperties, false, mathcingPattern.keys[0]);
        }

        this.selectedCurrentPatternSearchResults.push({ ...mathcingPattern, keys: adjustedKeys });

        this.patternResultChanged.emit(this.selectedCurrentPatternSearchResults);

        this.changeDetectorRef.detectChanges();
    }

    public onFocus(): void {
        if (!this.isOmniBoxOpened) {
            this.searchInput.nativeElement.focus();
            this.onSearchInputFocusChange(true);
        }
    }

    public onBlur(event: FocusEvent): void {
        // when user wants to remove chip, input loses focus and mat-basic-chip receive. If other element receives focus - close omni box
        const isChipFocus = event.relatedTarget != null
            ? (event.relatedTarget as HTMLElement).tagName.toLowerCase() === 'mat-basic-chip'
            : false;

        if (!isChipFocus) {
            this.onSearchInputFocusChange(false);
        }
    }

    public onSearchInputFocusChange(isOmniBoxOpened: boolean): void {
        this.isOmniBoxOpened = isOmniBoxOpened;

        if (!this.isOmniBoxOpened) {
            this.searchInput.nativeElement.value = '';
            this.isPatternResultsOpened = false;
            this.searchInput.nativeElement.blur();
            this.valuesFormControl.reset();
            this.changeDetectorRef.detectChanges();
        } else {
            this.scrollService.scrollInContainer(
                '#' + this.chipScrollContainer.nativeElement.id,
                { top: this.chipScrollContainer.nativeElement.scrollHeight, behavior: 'smooth' }
            );
        }
    }

    private getIcon(searchResultType: string): string {
        switch (searchResultType.toLowerCase()) {
            case 'borough':
                return 'circle';
            case 'section':
                return 'romb';
            case 'neighborhood':
                return 'gallery';
            case 'street':
                return 'road';
            case 'building name':
                return 'building';
            case 'address':
                return 'address';
            case 'listing':
                return 'listing';
            case 'web':
                return 'web';
            default:
                return '';
        }
    }

    private getNeighborhoodChildrenIds(neighborhoodProperties: NeighborhoodProperty[], targetIdMatched: boolean, targetId: number = null): number[] {
        const childrenIds: number[] = [];

        for (const property of neighborhoodProperties) {
            if (property.id === targetId) {
                const foundChildrenIds = this.getNeighborhoodChildrenIds(property.children, true);

                childrenIds.push(...foundChildrenIds);

                if (!targetIdMatched && childrenIds.length === 0) {
                    childrenIds.push(property.id);

                    return childrenIds;
                }
            } else {
                if (targetIdMatched) {
                    childrenIds.push(property.id);
                }

                if (property.children.length > 0) {
                    const foundChildrenIds = this.getNeighborhoodChildrenIds(property.children, targetIdMatched, targetId);

                    childrenIds.push(...foundChildrenIds);
                }
            }
        }

        return childrenIds;
    }

}

export interface PatternSearchResultGroup {
    values: PatternSearchResultGroupValue[];
    icon?: string;
}

export interface PatternSearchResultGroupValue {
    value: string;
    keys: number[];
}