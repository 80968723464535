import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { CurrencyConstants } from '@core-constants/currency/currency.constants';
import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';
import { DECREASE_PRICE_CHANGE_TYPE } from '@listings/constants/listings.constants';
import { ListingPriceChange } from '@listings/models/listing/listing-price-change';
import { ListingPrices } from '@listings/models/listing/listing-prices';

@Component({
    selector: 'last-price-change',
    templateUrl: './last-price-change.component.html',
    styleUrls: ['./last-price-change.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LastPriceChangeComponent implements OnChanges, AfterViewInit {

    @Input() prices: ListingPrices;
    @Input() priceChange: ListingPriceChange;

    @Output() opened = new EventEmitter<void>();
    @Output() closed = new EventEmitter<void>();
    @Output() priceChangeClicked = new EventEmitter<void>();

    public formattedLastPrice: string;

    public isPriceIncreased: boolean;

    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef
    ) { }

    public ngOnChanges(changes: SimpleChanges<LastPriceChangeComponent>): void {
        if (changes.prices?.currentValue) {
            this.formattedLastPrice = new CurrencyPipe('en').transform(
                this.prices != null ? this.prices.soldPrice : null,
                CurrencyConstants.Codes.USD,
                CurrencyConstants.Symbols.USD,
                CurrencyConstants.Formats.WithoutFraction);
        }

        if (changes.priceChange?.currentValue) {
            this.isPriceIncreased = changes.priceChange?.currentValue?.type !== DECREASE_PRICE_CHANGE_TYPE;
        }

        this.changeDetectorRef.detectChanges();
    }

    public ngAfterViewInit(): void {
        this.changeDetectorRef.detach();
    }

    public onLastPriceChangeClicked(): void {
        this.opened.emit();
    }

    public onLastPriceChangeClosed(): void {
        setTimeout(() => this.closed.emit());
    }
}