import { createReducer, on } from '@ngrx/store';

import { APPOINTMENT_ERRORS_MAP } from '@appointments/constants/appointment-errors.constants';
import { CreateOperationListingAppointment, ListingAppointment } from '@appointments/models/appointments/listing-appointment';
import * as appointmentsActions from '@appointments/store/actions/appointments.actions';
import { AppointmentActionErrors } from '@appointments/store/enums/appointment-action-errors';
import { AppointmentsState } from '@appointments/store/states/appointments.state';

const createListingAppointment = (appointment: ListingAppointment | CreateOperationListingAppointment): ListingAppointment => {
    return new ListingAppointment(
        appointment.listingIdHashCode,
        appointment.startDateTime,
        appointment.endDateTime,
        appointment.createId,
        appointment.updateId,
        appointment.comment,
        appointment.viewed,
        appointment.creator,
        appointment.agentStatus,
        appointment.customerStatus,
        appointment.id,
        appointment.dateCreated
    );
};

const initialState = new AppointmentsState();

export const appointmentsReducer = createReducer(
    initialState,
    on(appointmentsActions.loadListingsAppointments, (state) => {
        return { ...state, isSingleAppointmentLoaded: false, appointmentsLoaded: false, isAppointmentsLoading: true };
    }),
    on(appointmentsActions.loadListingsAppointmentsSuccess, (state, action) => {
        return {
            ...state,
            listingsAppointments: action.appointments,
            isSingleAppointmentLoaded: true,
            appointmentsLoaded: true,
            isAppointmentsLoading: false
        };
    }),
    on(appointmentsActions.loadListingsAppointmentsFailed, (state, action) => {
        return {
            ...state,
            error: { error: AppointmentActionErrors.ListingsAppointmentsLoading },
            isSingleAppointmentLoaded: false,
            appointmentsLoaded: false,
            isAppointmentsLoading: false
        };
    }),
    on(appointmentsActions.loadListingAppointments, (state, action) => {
        return { ...state, isSingleAppointmentLoaded: false };
    }),
    on(appointmentsActions.loadListingAppointmentsSuccsess, (state, action) => {
        const listingsAppointments = { ...state.listingsAppointments };

        if (action.appointments != null && action.appointments.length > 0) {
            listingsAppointments[action.appointments[0].listingIdHashCode] = action.appointments.map(createListingAppointment);
        }

        return { ...state, listingsAppointments, isSingleAppointmentLoaded: true, appointmentsLoaded: false };
    }),
    on(appointmentsActions.loadListingAppointmentsFailed, (state, action) => {
        return { ...state, error: { error: AppointmentActionErrors.ListingAppointmentsLoading }, isSingleAppointmentLoaded: false, appointmentsLoaded: false };
    }),
    on(appointmentsActions.createListingAppointment, (state, action) => {
        const listingsAppointments = { ...state.listingsAppointments };
        const listingAppointments = listingsAppointments[action.createOperationListingAppointment.listingIdHashCode];
        const updatedAppointments: (ListingAppointment | CreateOperationListingAppointment)[] = [];

        if (listingAppointments != null) {
            updatedAppointments.push(...listingAppointments.map(createListingAppointment));
        }

        // Insert temporary appointment with operation id
        updatedAppointments.push(new CreateOperationListingAppointment(
            action.createOperationListingAppointment.listingIdHashCode,
            action.createOperationListingAppointment.startDateTime,
            action.createOperationListingAppointment.endDateTime,
            action.createOperationListingAppointment.createId,
            action.createOperationListingAppointment.comment,
            action.createOperationListingAppointment.operationId,
            action.createOperationListingAppointment.isNewlyAdded
        ));
        listingsAppointments[action.createOperationListingAppointment.listingIdHashCode] = updatedAppointments;

        return { ...state, listingsAppointments };
    }),
    on(appointmentsActions.createListingAppointmentSuccess, (state, action) => {
        const listingsAppointments = { ...state.listingsAppointments };
        const listingAppointments = listingsAppointments[action.request.createOperationListingAppointment.listingIdHashCode];
        const updatedAppointments = listingAppointments == null ? [] : listingAppointments.map(appointment => {
            // Replace temporary appointment with operation id to store db appointment id when request completed
            if (action.request.createOperationListingAppointment.operationId === (appointment as CreateOperationListingAppointment).operationId) {
                return createListingAppointment(action.appointment);
            }

            return createListingAppointment(appointment);

        });

        listingsAppointments[action.request.createOperationListingAppointment.listingIdHashCode] = updatedAppointments;

        return { ...state, listingsAppointments };
    }),
    on(appointmentsActions.createListingAppointmentFailed, (state, action) => {
        const listingsAppointments = { ...state.listingsAppointments };
        const listingAppointments = listingsAppointments[action.request.createOperationListingAppointment.listingIdHashCode];
        const updatedAppointments = listingAppointments
            // Filter temporary appointment with operation id as request failed
            .filter(appointment =>
                action.request.createOperationListingAppointment.operationId !== (appointment as CreateOperationListingAppointment).operationId)
            .map(createListingAppointment);

        listingsAppointments[action.request.createOperationListingAppointment.listingIdHashCode] = updatedAppointments;
        const error = APPOINTMENT_ERRORS_MAP.has(action.error.errorKey)
            ? APPOINTMENT_ERRORS_MAP.get(action.error.errorKey)
            : AppointmentActionErrors.AppointmentCreation;

        return { ...state, listingsAppointments, error: { error } };
    }),
    on(appointmentsActions.updateListingAppointment, (state, action) => {
        const listingsAppointments = { ...state.listingsAppointments };
        const listingAppointments = listingsAppointments[action.listingAppointment.listingIdHashCode];
        const updatedAppointments = listingAppointments.map(appointment => {
            if (appointment.id === action.listingAppointment.id) {
                return createListingAppointment(action.listingAppointment);
            }

            return createListingAppointment(appointment);

        });

        listingsAppointments[action.listingAppointment.listingIdHashCode] = updatedAppointments;

        return { ...state, listingsAppointments };
    }),
    on(appointmentsActions.updateListingAppointmentFailed, (state, action) => {
        const listingsAppointments = { ...state.listingsAppointments };
        const listingAppointments = listingsAppointments[action.request.listingAppointment.listingIdHashCode];
        const updatedAppointments = listingAppointments.map(appointment => {
            if (appointment.id === action.request.listingAppointment.id) {
                return createListingAppointment(action.request.oldListingAppointment);
            }

            return createListingAppointment(appointment);

        });

        listingsAppointments[action.request.listingAppointment.listingIdHashCode] = updatedAppointments;
        const error = APPOINTMENT_ERRORS_MAP.has(action.error.errorKey)
            ? APPOINTMENT_ERRORS_MAP.get(action.error.errorKey)
            : AppointmentActionErrors.AppointmentUpdate;

        return { ...state, listingsAppointments, error: { error } };
    }),
    on(appointmentsActions.changeAppointmentStatus, (state, { request }) => {
        if (request.oldListingAppointment == null) {
            return state;
        }

        const listingsAppointments = { ...state.listingsAppointments };
        const listingAppointments = listingsAppointments[request.oldListingAppointment.listingIdHashCode];
        const updatedAppointments = listingAppointments.map(appointment => {
            if (appointment.id === request.appointmentId) {
                return new ListingAppointment(
                    request.oldListingAppointment.listingIdHashCode,
                    request.oldListingAppointment.startDateTime,
                    request.oldListingAppointment.endDateTime,
                    request.oldListingAppointment.createId,
                    request.updateId,
                    request.oldListingAppointment.comment,
                    request.oldListingAppointment.viewed,
                    request.oldListingAppointment.creator,
                    request.oldListingAppointment.agentStatus,
                    request.customerStatus,
                    request.oldListingAppointment.id,
                    request.oldListingAppointment.dateCreated
                );
            }

            return createListingAppointment(appointment);

        });

        listingsAppointments[request.oldListingAppointment.listingIdHashCode] = updatedAppointments;

        return { ...state, listingsAppointments };
    }),
    on(appointmentsActions.changeAppointmentStatusFailed, (state, action) => {
        if (action.request.oldListingAppointment == null) {
            return state;
        }

        const listingsAppointments = { ...state.listingsAppointments };
        const listingAppointments = listingsAppointments[action.request.oldListingAppointment.listingIdHashCode];
        const updatedAppointments = listingAppointments.map(appointment => {
            if (appointment.id === action.request.appointmentId) {
                return createListingAppointment(action.request.oldListingAppointment);
            }

            return createListingAppointment(appointment);

        });

        listingsAppointments[action.request.oldListingAppointment.listingIdHashCode] = updatedAppointments;
        const error = APPOINTMENT_ERRORS_MAP.has(action.error.errorKey)
            ? APPOINTMENT_ERRORS_MAP.get(action.error.errorKey)
            : AppointmentActionErrors.AppointmentStatusUpdate;

        return { ...state, listingsAppointments, error: { error } };
    }),
    on(appointmentsActions.markAppointmentsAsViewedSuccess, (state, action) => {
        const listingsAppointments = { ...state.listingsAppointments };

        action.listingsAppointments.forEach(listingAppointments => {
            const appointments = listingsAppointments[listingAppointments.listingIdHashCode];
            const updatedAppointments = appointments.map(appointment => {
                if (listingAppointments.appointmentIds.includes(appointment.id)) {
                    return new ListingAppointment(
                        appointment.listingIdHashCode,
                        appointment.startDateTime,
                        appointment.endDateTime,
                        appointment.createId,
                        appointment.updateId,
                        appointment.comment,
                        true,
                        appointment.creator,
                        appointment.agentStatus,
                        appointment.customerStatus,
                        appointment.id,
                        appointment.dateCreated
                    );
                }

                return createListingAppointment(appointment);

            });

            listingsAppointments[listingAppointments.listingIdHashCode] = updatedAppointments;
        });

        return { ...state, listingsAppointments };
    }),
    on(appointmentsActions.markAppointmentsAsViewedFailed, (state, action) => {
        const listingsAppointments = { ...state.listingsAppointments };

        action.request.listingsAppointments.forEach(listingAppointments => {
            const appointments = listingsAppointments[listingAppointments.listingIdHashCode];
            const updatedAppointments = appointments.map(appointment => {
                if (listingAppointments.appointmentIds.includes(appointment.id)) {
                    return new ListingAppointment(
                        appointment.listingIdHashCode,
                        appointment.startDateTime,
                        appointment.endDateTime,
                        appointment.createId,
                        appointment.updateId,
                        appointment.comment,
                        false,
                        appointment.creator,
                        appointment.agentStatus,
                        appointment.customerStatus,
                        appointment.id,
                        appointment.dateCreated
                    );
                }

                return createListingAppointment(appointment);

            });

            listingsAppointments[listingAppointments.listingIdHashCode] = updatedAppointments;
        });
        const error = APPOINTMENT_ERRORS_MAP.has(action.error.errorKey)
            ? APPOINTMENT_ERRORS_MAP.get(action.error.errorKey)
            : AppointmentActionErrors.AppointmentDeletion;

        return { ...state, listingsAppointments, error: { error } };
    }),
    on(appointmentsActions.deleteListingAppointment, (state, { model }) => {
        const listingsAppointments = { ...state.listingsAppointments };
        const listingAppointments = listingsAppointments[model.listingIdHashCode] ?? [];

        const updatedAppointments = listingAppointments.filter(x => model.appointmentId !== x.id);

        listingsAppointments[model.listingIdHashCode] = updatedAppointments;

        return { ...state, listingsAppointments };
    }),
    on(appointmentsActions.deleteListingAppointmentFailed, (state, action) => {
        const listingsAppointments = { ...state.listingsAppointments };
        const listingAppointments = listingsAppointments[action.request.listingAppointment.listingIdHashCode];
        const updatedAppointments: (ListingAppointment | CreateOperationListingAppointment)[] = [];

        if (listingAppointments != null) {
            updatedAppointments.push(...listingAppointments.map(createListingAppointment));
        }

        // Insert temporary appointment with operation id
        updatedAppointments.push(createListingAppointment(action.request.listingAppointment));
        listingsAppointments[action.request.listingAppointment.listingIdHashCode] = updatedAppointments;

        return { ...state, listingsAppointments, error: { error: AppointmentActionErrors.AppointmentDeletion } };
    }),
    on(appointmentsActions.loadAppointmentSuccess, (state, { appointment }) => {
        const listingsAppointments = { ...state.listingsAppointments };
        const updatedListingAppointments = [...(listingsAppointments[appointment.listingIdHashCode] ?? []).filter(x => x.id !== appointment.id), appointment];

        listingsAppointments[appointment.listingIdHashCode] = updatedListingAppointments;

        return { ...state, listingsAppointments };
    }),
    on(appointmentsActions.resetState, () => ({ ...initialState })),
    on(appointmentsActions.setAppointments, (state, { appointments }) => ({ ...state, listingsAppointments: appointments }))
);