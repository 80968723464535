<div class="rpc-new-version">

    <div class="rpc-new-version-body">

        <div class="rpc-new-version-image-wrap">

            <div class="rpc-new-version-image"
                [style.--bg]="'url(/assets/images/decoration/new-version/new-version.webp)'">
            </div>

        </div>

        <div class="rpc-new-version-info">

            <div class="rpc-new-version-title">
                {{ 'NEW_VERSION_DIALOG.TITLES.UPDATE_AVAILABLE' | translate }}
            </div>

            <div class="rpc-new-version-text">
                {{ 'NEW_VERSION_DIALOG.TITLES.NEW_VERSION' | translate: { productName: productName$ | async } }}
            </div>

        </div>

    </div>

    <div class="rpc-new-version-footer">

        <rpc-button class="rpc-new-version-btn" [appearanceType]="'flat'" [color]="'accent'"
            [title]="'NEW_VERSION_DIALOG.TITLES.RELAUNCH_NOW'" (clicked)="onUpdateClicked()">
        </rpc-button>

    </div>

</div>